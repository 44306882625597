import React, { useEffect, useRef, useState } from "react";
import { Button, FormControl, Form, Modal } from "react-bootstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Swal from "sweetalert2";
import Spinner from "react-bootstrap/Spinner";
import { useCreateLogPageMutation } from "../../redux/apiSlice/logPageApiSlice.js";
import {
  useCreateResObservationMutation,
  useUpdateResultatInvesPlainteMutation,
  useGetResultatObsQuery,
} from "../../redux/apiSlice/plainteApiSlice.js";
import { isEmpty } from "lodash";
export default function ResultatDesInvestigations({ display2, handleClose2, id_plt}) {
  const [createResultatInvestigation, {isLoading: loadCreate}] = useCreateResObservationMutation()
  const [updateResultatInvestigation, {isLoading: loadUpdate}] = useUpdateResultatInvesPlainteMutation();
  const {data: resultatInvestigation = {}, isLoading: loadGetResult, isSuccess} = useGetResultatObsQuery(id_plt, {skip: !id_plt})
  const [createLogPage, { isLoading: loadLog }] = useCreateLogPageMutation();
  
  const [files, setFiles] = useState([])
  const [editorContent, setEditorContent] = useState("");
  const formData = new FormData();

  const handleFileChange = async (e) => {
    const selectedFiles = e.target.files;
    setFiles(selectedFiles);
  };

  const handleChange = (event, editor) => {
    const content = editor.getData()
    setEditorContent(content);
  };

  const editorRef = useRef();

  useEffect(() => {
    if (!loadGetResult && isSuccess) setEditorContent(!isEmpty(resultatInvestigation) ? resultatInvestigation.comment_res : "")
  }, [loadGetResult, isSuccess])

  const createLogActionPlainte = async ()=>{
    try {
      await createLogPage({content: "Résultat d'Investigation"}).unwrap();    
  } catch (error) {
    console.log('error', error)
  }
  }

  const upload = () => {
  
    for (let i = 0; i < files.length; i++) {
      formData.append("images[]", files[i]);
    }

    try {
      Swal.fire({
        title: "Action irréversible !",
        text: "Cette action est irréversible !",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oui", cancelButtonText: "Non",
      }).then(async (result) => {
        if (result.isConfirmed === true) {
          let result;
          if (isEmpty(resultatInvestigation)) {
          
            formData.append("comment_res", editorContent);
            formData.append("id_plt", id_plt);
            result = await createResultatInvestigation(formData).unwrap();
          } else {
         
            formData.append("comment_res", editorContent);
          //   for (let [key, value] of formData.entries()) {
          //     console.log(key, value);
          // }
            result = await updateResultatInvestigation({id: resultatInvestigation.id_res, formData}).unwrap();

          }
          createLogActionPlainte()
          if (result) {
           window.location.href = "/toutes_les_plaintes"
          }
        }
      });

      // refetchPlaintes();
    } catch (error) {
      console.error("Error creating obs state:", error);
    }
  };

  return (
    <div class="container">
      <Modal
        show={display2}
        onHide={handleClose2}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title className="w-100 text-center" style={{fontSize : 30, color: "#000",}}>Détails Plainte</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="mb-2">
                  <h5>Veuillez entrer les résultats de l'investigation</h5>
                  <Form.Group controlId="formBasicText">
                    <CKEditor
                      editor={ClassicEditor}
                      data={editorContent}
                      onReady={(editor) => editorRef.current = editor}
                      onChange={handleChange}
                    />
                  </Form.Group>

                  <Form.Group controlId="formBasicText">
                    <Form.Label>Fichier(s)</Form.Label>
                    <Form.Control
                      onChange={handleFileChange}
                      type="file"
                      id="exampleFormControlInput1"
                      placeholder=""
                      name="images"
                      multiple
                    />
                  </Form.Group>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={editorContent === ""}
            onClick={upload}
            variant="success"
          >
            {" "}
            {loadCreate || loadUpdate ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              <span>Valider </span>
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
