import React, { useEffect, useState } from "react";
import { Button, Modal, Row, Col, Alert, Form} from "react-bootstrap";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import Sidebar from "../../layouts/Sidebar";
import Stepper from "react-stepper-horizontal";
import { NavLink } from "react-router-dom";
import Step0 from "./Step0";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Spinner from "react-bootstrap/Spinner";
import LookAtPlaint from "./LookAtPlaint";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from "sweetalert2"
import { useSelector, useDispatch} from "react-redux";
import { getUserInfo, isTokenExpired, logOut } from "../../redux/slice/userSlice.js";
import {
  useGetPlaintesQuery,
  useCreatePlainteMutation,
  useUpdatePlainteMutation,
} from "../../redux/apiSlice/plainteApiSlice.js";
import VisualisationPlainte from "./VisualisationPlainte.js";
import { useCreateLogPageMutation } from "../../redux/apiSlice/logPageApiSlice";

export default function DepotPLaintes() {
  ///// Skin Switch /////
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);
  const [aff_plainsanterie, setAff_plainsanterie] = useState(0);
  const [id_user, setId_user] = useState(null);
 
   // modal
  const [show, setShow] = useState(false);
  const handleClose = () => {setShow(false)};
  const handleShow = () => {setShow(true)};

  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  switchSkin(skin);

  const [currentStep, setCurrentStep] = useState(0);
 
  const [createPlainte, { isLoading: loadCreatePlainte }] =
    useCreatePlainteMutation();
  const [updatePlainte, { isLoading: loadUpdatePlainte }] =
    useUpdatePlainteMutation();

  const {
    data: plaintes,
    refetch: refetchPlaintes,
    isLoading: loadPlaintes,
  } = useGetPlaintesQuery();
  
    const [createLogPage, { isLoading: loadLog }] = useCreateLogPageMutation();

  const [response, setResponse] = useState();

  const [dummyDatas, setDummyData] = useState({
    type_plainte: "",
    nom_operateur: "",
    lib_canal: "",
    lib_solution: "",
  })

  const connectVerify = useSelector(getUserInfo);
  const isExpired = useSelector(isTokenExpired);
  const dispash = useDispatch();


  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const initPlainte = {
    raison: "",
    nom_plt: "",
    prenom_plt: "",
    sexe: "",
    tel_plt: "",
    email_plt: "",
    adresse_plt: "",
    ref_plt: "",
    motif_plt: "Rien",
    desc_plt: "",
    id_solution: 0,
    id_operateur: NaN,
    id_user : connectVerify?.id,
    id_stat_plt: 1,
    type_personne: "0",
    recours: "0",
    delai: "0",
    repondu: "",
    profession: "",
    nationalite: "",
    date_constat: null,
    canal_retour: "email",
    reponse: "-",
    images: [],
    currentStep: currentStep,
  };
  const initPlaintePlaisanterie = {
    raison: "-",
    nom_plt: "-",
    prenom_plt: "-",
    sexe: "-",
    tel_plt: "-",
    email_plt: "",
    adresse_plt: "-",
    ref_plt: "",
    motif_plt: "Rien",
    desc_plt: "",
    // id_solution: 0,
    id_canal: 4,
    // id_operateur: "",
    id_user : connectVerify?.id,
    id_stat_plt: 1,
    type_personne: "0",
    recours: "0",
    delai: "0",
    repondu: "-",
    profession: "-",
    nationalite: "-",
    date_constat: null,
    canal_retour: "email",
    reponse: "-",
    images: [],
    id_type_req: 9,
    currentStep: currentStep,
  };
  const [displayMe, setDisplayMe] = useState("flex");

  const [plainteData, setPlainteData] = useState({ ...initPlainte});
 
  const [plainteDataPlaisanterie, setPlainteDataPlaisanterie] = useState({ ...initPlaintePlaisanterie });
  let [disabled, setDisabled] = useState(true);
  const steps = [
    { title: "Identification de la plainte" },
    { title: "Identification du plaignant" },
    { title: "Information sur la plainte" },
    { title: "Prévisualiser la plainte" },
  ];
  const createLog = async ()=>{
    try {
      await createLogPage({content: "Page de creation de plainte"}).unwrap();  
  } catch (error) {
   
    console.log('error', error)
  }
  } 
  useEffect(() => {
    createLog()   
  }, []);
  
  useEffect(() => {
    if (isExpired) {
     dispash(logOut())
     window.location.href = "/";
     }  
   }, [isExpired]);

  useEffect(() => {
    let mon_user;
    const storedData = localStorage.getItem("mon_user");
    if (storedData) {
      mon_user = JSON.parse(storedData);
      setId_user(mon_user?.id)
    } else {
      console.log("Aucune donnée utilisateur trouvée dans le localStorage");
    }

    if (localStorage.getItem("token") === "" || !localStorage.getItem("token")) {
      window.location.href = "/";
    }
    if (plainteData.recours === "0" || plainteData.delai === "0" ) {
      setDisplayMe("none");
    } else if (plainteData.recours === "1" && plainteData.delai === "1") {
      setDisplayMe("flex");
    } else if (plainteData.recours === "2") {
      setDisplayMe("flex");
    } else {
    }
  }, [plainteData, currentStep]);

  const checkDisable = () => {
   
    let result
    switch (currentStep) {
      case 0:
        if (
          
          !isNaN(plainteData.id_operateur) &&
          plainteData.id_canal != "" &&
          plainteData.recours !== "" &&
          plainteData.delai !== "" &&
          plainteData.repondu !== ""
        ) {
          result = false
        } else if (
         
          isNaN(plainteData.id_operateur) ||
          plainteData.id_canal == "" ||
          plainteData.ref_plt === "" ||
          plainteData.delai === "" ||
          plainteData.recours === "" ||
          plainteData.repondu === ""
        ) {
          result = true
        }
        break;
      case 1:
        if (
          plainteData.type_personne !== "" &&
          plainteData.nom_plt !== "" &&
          plainteData.prenom_plt !== "" &&
          plainteData.sexe !== "" &&
          plainteData.tel_plt !== "" &&
          plainteData.nationalite !== "" &&
          plainteData.profession !== "" 
          
        ) {
          result = false
        } else if (
          plainteData.type_personne === "" ||
          plainteData.nom_plt === "" ||
          plainteData.prenom_plt === "" ||
          plainteData.sexe === "" ||
          plainteData.nationalite === "" ||
          plainteData.profession === "" ||
          plainteData.tel_plt === "" 
        ) {
          result = true
        }

        break;
      case 2:

      if (plainteData.lib_solution) {
        if (
          plainteData.desc_plt !== "" &&
          plainteData.lib_solution != "" &&
          plainteData.canal_retour !== "" 
        
        ) {
          result = false
        } else if (
          plainteData.desc_plt === "" ||
          plainteData.lib_solution == "" ||
          plainteData.canal_retour === "" 
         
        ) {
          result = true
        }
      } else {
        if (
          plainteData.desc_plt !== "" &&
          plainteData.id_solution != 0 &&
          plainteData.canal_retour !== "" 
        
        ) {
          result = false
        } else if (
          plainteData.desc_plt === "" ||
          plainteData.id_solution == 0 ||
          plainteData.canal_retour === "" 
         
        ) {
          result = true
        }
      }
        
      
        break;
      default:
        result = true
        break;
    }

    return result
  }

  const handleNext = () => {
    //setDisabled(true);

    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  const controleChoixRetour= ()=>{

    if (currentStep == 2) {
      if (plainteData.canal_retour == "email" && plainteData.email_plt == "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Veuillez renseigner votre adresse électronique pour cette option de retour"
      });
      } else {
        handleNext()
      }
    } else {
      handleNext()
    }
   
    
  }

  const changePlainteData = (field, value) =>
    setPlainteData({ ...plainteData, [field]: value });

  const handlePrev = () => {
    changePlainteData('images', [])
    switch (currentStep) {
      case 0:
        setDisabled(false);
        break;
      case 1:
        setDisabled(false);
        break;
      case 2:
        setDisabled(false);
        break;
      case 3:
        setDisabled(true);
        break;
      default:
        setDisabled(true);
        break;
    }
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  async function showToast() {
    return new Promise((resolve) => {
      setTimeout(() => {
        toast.success("Plainte ajoutée avec succès.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        resolve();
      }, 0); // Délai minimal
    });
  }

  const crudPlainte = async () => {
   
      switch (currentStep) {
        case 3:
          
          try {
            const formData = new FormData();
            Object.keys(plainteData).forEach((key) => {
              if (key !== "images") {
                formData.append(`${key}`, plainteData[key]);
              }
            });
            for (let i = 0; i < plainteData.images.length; i++) {
              formData.append("images[]", plainteData.images[i]);
            }
  
            const retour_plainte = await createPlainte(formData).unwrap();
        
            refetchPlaintes();
         
            if (retour_plainte.data) {
            
                // window.location.href = "/depot-plainte";
                window.location.href = "/depot-plainte?register=true" ;
  
            }
  
            // if (retour_plainte.data) {
            //   toast.error("Erreur système. Veuillez réessayer.", {
            //     position: toast.POSITION.BOTTOM_RIGHT,
            //   });
              // window.location.href = "/depot-plainte"
            // }
          } catch (error) {
            
            // console.log("error", error)

               if (error.data.num_suivi_plt) {
            window.location.href = "/depot-plainte?register=true" ;
               }else{
        toast.error("Une erreur s'est produite, veuillez réessayer.", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
               }
        
            
            // console.log(error, 'ererer')
            // if (error.status) {
            //   console.error('Erreur HTTP :', error.status);
            // } else {
            //   console.error('Erreur lors de la requête :', error.message);
            // }
          }
          break;
  
        default:
          setCurrentStep(0);
          break;
      }
    
  };
  //   const handleChange = (e) => {
  //    const { name, value } = e.target;
  //    setFormData({ ...formData, [name]: value });
  //  };

  const choixAJout = (value)=>{
    switch (parseInt(value)) {
      case 1:
        setAff_plainsanterie(1)
        break;
      case 2:
        setAff_plainsanterie(2)
        handleShow()
        break;
      default:
        break;
    }
  }

  const crudPlaisanterie = async () => {
  
    try {
      const formData = new FormData();
      Object.keys(plainteDataPlaisanterie).forEach((key) => {
        if (key !== "images") {
          formData.append(`${key}`, plainteDataPlaisanterie[key]);
        }
      });
      // for (let i = 0; i < plainteDataPlaisanterie.images.length; i++) {
      //   formData.append("images[]", plainteDataPlaisanterie.images[i]);
      // }
      const retour_plainte = await createPlainte(formData).unwrap();
      
      refetchPlaintes();

      if (retour_plainte.data) {
      
        window.location.href = "/depot-plainte?register=false" ;
        

      }

      


    } catch (error) {

      // console.log("error", error)
    
      
      if (error.data.num_suivi_plt) {
        window.location.href = "/depot-plainte?register=false" ;
           }else{
    toast.error("Une erreur s'est produite, veuillez réessayer.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
           }

  
    }
  
};



  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  return (
    <React.Fragment>
      <Sidebar />
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <NavLink to="/depot-plainte">Soummission de plainte</NavLink>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Plaintes
              </li>
            </ol>
            <h4 className="main-title mb-0">Dépot de plaintes</h4>
          </div>
        </div>

        <div className="container ">
          <Stepper steps={steps} activeStep={currentStep} />

          <div>
            {/* {currentStep === "disapear" && <div></div>} */}
            {currentStep === 0 && (
               <>
            { 
            (aff_plainsanterie == 0 )  &&
            <Row>
              <Col>
              <Alert variant="warning">
           <h5> Cette plainte satisfait-elle toutes les Informations</h5>
        </Alert>
        </Col>
        <Row>
        </Row>
              <Col>
              <Form.Check
            type="radio"
            label="Oui"
            value={1}
            checked={aff_plainsanterie == 1}

            onChange={(e) => choixAJout(e.target.value)}
          />
          <Form.Check
            type="radio"
            label="Non"
            value={2}
            checked={aff_plainsanterie == 2}
            onChange={(e) => choixAJout(e.target.value)}
          />
         
              </Col>
             </Row>}
             
         {
         aff_plainsanterie == 1 &&
(<Step0
           
                dummyDatas={dummyDatas}
                setDummyData={setDummyData}
                displayme={displayMe}
                plainteData={plainteData}
                setPlainteData={setPlainteData}
                enableOrDisable={
                  setDisabled
                }
              />)
         }
              
              </>
            )}
            {currentStep === 1 && (
              <Step1
                plainteData={plainteData}
                setPlainteData={setPlainteData}
                enableOrDisable={
                  setDisabled
                }
              />
            )}
            {currentStep === 2 && (
              <Step2
              dummyDatas={dummyDatas}
              setDummyData={setDummyData}
                plainteData={plainteData}
                setPlainteData={setPlainteData}
                enableOrDisable={
                  setDisabled
                }
              />
            )}
            {currentStep === 3 && (
              /*  */
              <>
              <VisualisationPlainte
                dummyDatas={dummyDatas}
                plainteData={plainteData}
               
              />
              <LookAtPlaint
                dummyDatas={dummyDatas}
                plainteData={plainteData}
                enableOrDisable={
                  setDisabled
                }
              />
              </>

            )}
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col d-flex justify-content-center align-items-center">
              {currentStep > 0 && (
                <Button onClick={handlePrev} variant="primary">
                  Précédent
                </Button>
              )}
            </div>
            <div className="col"></div>

            <div className="col d-flex justify-content-center align-items-center">
              {currentStep < steps.length - 1 && (
                <div style={{ display: displayMe }}>
                  <Button
                  onClick={controleChoixRetour}
                  variant="success"
                  disabled={checkDisable()}
                >
                  Suivant
                </Button></div>
              )}
              {currentStep === 3 && (
                <Button variant="success" onClick={() => crudPlainte()}>
                  {loadCreatePlainte || loadUpdatePlainte ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    <span>Valider la plainte</span>
                  )}
                </Button>
              )}
            </div>
          </div>
        </div>
        <Footer />
        {/* ----------------Modal d'enrrégistrement direct de plainsanterie----------- */}
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>Appels non aboutis</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
            <Form.Label htmlFor="num">
              Téléphone *
            </Form.Label>
                    <Form.Control
                      
                        onChange={(v)=>setPlainteDataPlaisanterie({...plainteDataPlaisanterie, tel_plt : v.target.value})}
                        type="number"
                        value={plainteDataPlaisanterie.tel_plt}
                        id="num"
                        placeholder="Téléphone"
                        required
                        pattern="[0-9]*"
                        style={{
                            border: "1px solid #1f365c",
                            boxShadow: "0 0 5px rgba(0, 0, 255, 0.5)",
                            borderRadius: "5px",
                          }}
                    />
            </Col>
          </Row>
          <Row>
            <Col>
            <Form.Label htmlFor="desc">
            Résumé de la requête *
          </Form.Label>
          <Form.Control
            onChange={(v)=>setPlainteDataPlaisanterie({...plainteDataPlaisanterie, desc_plt : v.target.value})}
            as="textarea"
            value={plainteDataPlaisanterie.desc_plt}
            id="desc"
            rows="3"
            placeholder="Entrer votre détails..."
            style={{
              border: "1px solid #1f365c",
              boxShadow: "0 0 5px rgba(0, 0, 255, 0.5)",
              borderRadius: "5px",
              padding: "8px"
            }}
          ></Form.Control></Col>
          </Row>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={()=>window.location.reload()}>
            Annuler
          </Button>
          <Button variant="success" disabled={plainteDataPlaisanterie.desc_plt =="" || plainteDataPlaisanterie.tel_plt ==""} onClick={()=>crudPlaisanterie()}>
            {loadCreatePlainte ? (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : (
                      <span> Enregistrer </span>
                    )}
          </Button>
        </Modal.Footer>
      </Modal>
      </div>
    </React.Fragment>
  );
}
