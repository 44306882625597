import { createSlice } from "@reduxjs/toolkit";

const plaintesSlice = createSlice({
  name: "plaintes",
  initialState: {
    info: [],
  },
  reducers: {
    setPlaintes: (state, action) => {
      state.info = action.payload;
    },
  },
});

export const { setPlaintes } = plaintesSlice.actions;

export const getPlaintes = (state) => state.info;

export default plaintesSlice.reducer;
