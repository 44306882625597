import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import Select from "react-select";
import { getOperateurs } from "../../redux/slice/operateurSlice";
import { useGetTypeOperateursQuery } from "../../redux/slice/typeOperateurApiSlice.js";
import {
  useGetServicesQuery,
  useGetCanauxReceptionQuery,
} from "../../redux/apiSlice/plainteApiSlice.js";
import { useGetOperateursQuery } from "../../redux/apiSlice/operateurApiSlice.js";

const Step0 = (props) => {
  const { dummyDatas, setDummyData, displayme, plainteData, setPlainteData, enableOrDisable } = props;
  const operateurs = useSelector(getOperateurs);
  const {
    data: services,
    isSuccess: isSuccessServices
  } = useGetServicesQuery();

  const {
    data: canaux,
  } = useGetCanauxReceptionQuery();
  const {data: operateur = [], isLoading: loadOperateur} = useGetOperateursQuery()
  const [refPlainteError, setRefPlainteError] = useState();
  const [motifPlainteError, setMotifPlainteError] = useState();
  const [typeError, setTypeError] = useState();
  const [canalError, setCanalError] = useState();
  const [opState, setOpState] = useState([]);

  const {data: typeOperateur = [], isLoading: loadTypeObligations} = useGetTypeOperateursQuery()

  // Validation functions
  const validateNonEmpty = (value, setValidationError) => {
    const isValid = value.trim() !== "";
    setValidationError(isValid ? null : 'Champ requis');
    //isValid ? enableOrDisable(false) : enableOrDisable(true);
    return isValid;
  };

  const handleRefPlainteChange = (e) => {
    const ref = e.target.value;
    validateNonEmpty(ref, setRefPlainteError);
    changePlainteData('ref_plt', ref);
  };

  

  const validateSelect = (value, setValidationError, key) => {
    const isValid = value!== "";
    setValidationError(isValid ? null : 'Champ requis');
    changePlainteData(key, parseInt(value))
  }

  const chargeOperateur = (value)=>{
    const tabFilter = operateur.filter((item)=>item.id_type_op == parseInt(value))
    setOpState(tabFilter)
  }

  const handleOperateurChange = (e) => {
    const operateur = e.value;
    validateSelect(operateur, setTypeError, "id_operateur");
    const selectedOperator = operateurs.find(
      (operator) => operator.id_operateur === parseInt(operateur)
    );
    changeDummyData("nom_operateur", selectedOperator.nom_operateur);
};

  const changePlainteData = (field, value) =>
    setPlainteData({ ...plainteData, [field]: value });
  const changeDummyData = (field, value) =>
    setDummyData({ ...dummyDatas, [field]: value });

  const changeCanaux = (value)=>{
    
    validateSelect(value, setCanalError, "id_canal");
    const canauxFiltre = canaux.find((item)=>item.id_canal == value)
    changeDummyData("lib_canal", canauxFiltre?.lib_canal)
  }

  useEffect(() => {

  }, [isSuccessServices, services]);

  return (
    <div className="container p-4">
      <h6 className="p-2">Identification de la plainte</h6>

      <div className="row">
        <div className="col">
          <h6>
            Avez-vous fait un recours à l'opérateur au sujet de votre plainte
            ? *
          </h6>
          <Form.Check
            type="radio"
            label="Oui"
            value="1"
            checked={plainteData.recours === "1"}
            onChange={(e) => changePlainteData("recours", e.target.value)}
          />
  
          <Form.Check
            type="radio"
            label="Non"
            value="0"
            checked={plainteData.recours === "0"}
            onChange={(e) => changePlainteData("recours", e.target.value)}
          />
        </div>
      </div>
      <div className="row">
        <div className="col"  style={{ display: ['0'].includes(plainteData.recours) ? 'none' : 'block' }}>
          <h6>
          la durée fait déjà au moins un mois ?*
          </h6>
          <Form.Check
            type="radio"
            label="Oui"
            value="1"
            checked={plainteData.delai === "1"}
            onChange={(e) => changePlainteData("delai", e.target.value)}
          />
         
          <Form.Check
            type="radio"
            label="Non"
            value="0"
            checked={plainteData.delai === "0"}
            onChange={(e) => changePlainteData("delai", e.target.value)}
          />
        </div>
        
          <div className="col" style={{ display: displayme, flexDirection:"column" }}>
          <h6>
            Avez-vous obtenu une réponse de la part de votre opérateur ? *
          </h6>
          <>
          <Form.Check
            type="radio"
            label="Oui mais pas satisfait(e)"
            value="1"
            checked={plainteData.repondu === "1"}
            onChange={(e) => changePlainteData("repondu", e.target.value)}
          />
          <Form.Check
            type="radio"
            label="Non"
            value="0"
            checked={plainteData.repondu === "0"}
            onChange={(e) => changePlainteData("repondu", e.target.value)}
          />
          </>
        </div>
       
      </div>
     
   
     
      <div style={{ display: displayme }} className="row mt-4">
      <div className="row">
    <div className="col mb-3">

            <Form.Label htmlFor="exampleFormControlInput1">
              Référence de la plainte chez l'opérateur :
            </Form.Label>
            <Form.Control
              type="text"
              id="exampleFormControlInput1"
              placeholder="Référence"
              value={plainteData.ref_plt}
              onChange={handleRefPlainteChange}
              style={{
                border: "1px solid #1f365c",
                boxShadow: "0 0 5px rgba(0, 0, 255, 0.5)",
                borderRadius: "5px",
              }}
            />     
        </div>
        <div className="col">
          <div className="mb-3">
          <Form.Label htmlFor="exampleFormControlInput1">
            Type d'Opérateur *:
          </Form.Label>
          <Form.Select
            onChange={(e) => {chargeOperateur(e.target.value)}
            }
            
            style={{
              border: "1px solid #1f365c",
              boxShadow: "0 0 5px rgba(0, 0, 255, 0.5)",
              borderRadius: "5px",
            }}
          >
            <option>Choisir</option>
            {typeOperateur?.map((item, key) => {
 
 return     ( <option key={key} value={item.id_type_op}>
                    {item.lib_type_op}
                  </option>)
                
            })}
          </Form.Select>
          </div>
        </div>
    </div>
         <div className="row">
        
        <div className="col">
          <Form.Label htmlFor="exampleFormControlInput1">
            Opérateur concerné *:
          </Form.Label>
          <Select 
 options={opState.map((item)=>{
   return{
     value : item.id_operateur,
     label : item.nom_operateur
   }
 })} 
 isSearchable={true} 
 onChange={handleOperateurChange}
 styles={{
   control: (provided) => ({
     ...provided,
     border: "1px solid #1f365c",
     boxShadow: "0 0 5px rgba(0, 0, 255, 0.5)",
     borderRadius: "5px",
     
   })
 }}
/>
          {typeError && (
            <span style={{ color: 'red', fontSize: '15px' }}>{typeError}</span>
          )}
        </div>
        <div className="col">
          <Form.Label htmlFor="exampleFormControlInput1">
            Canal de Réception *:
          </Form.Label>
          <Form.Select
            onChange={(e) => changeCanaux(e.target.value)}
            
            style={{
              border: "1px solid #1f365c",
              boxShadow: "0 0 5px rgba(0, 0, 255, 0.5)",
              borderRadius: "5px",
            }}
          >
            <option>Choisir</option>
            {canaux?.map((item, key) => {
 
 return     ( <option key={key} value={item.id_canal}>
                    {item.lib_canal}
                  </option>)
                
            })}
          </Form.Select>
          {canalError && (
            <span style={{ color: 'red', fontSize: '15px' }}>{canalError}</span>
          )}
        </div>
        </div>

       </div>
      <div style={{ display: displayme }} className="row">
        
      </div>
     
    </div>
  );
};

export default Step0;
