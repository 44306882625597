import { isEmpty } from 'lodash'
import React from 'react'
import { Stack } from 'rsuite'
import { useGetObsPlaintesQuery, useGetResultatObsQuery, useGetBackInvestQuery, useGetBackInstQuery } from '../../../redux/apiSlice/plainteApiSlice'
import Spinner from "react-bootstrap/Spinner";
const Observation = props => {
    const {plainte} = props

    const {data: observation = {} , isLoading: loadObs} = useGetObsPlaintesQuery(plainte.id_plt)
    const {data: resultatInvestigation = {}, isLoading: loadRslt} = useGetResultatObsQuery(plainte.id_plt)
    const {data: backInvestigation = [], isLoading: loadBackInv} = useGetBackInvestQuery(plainte.id_plt)
    const {data: backInstruction = [], isLoading: loadBackIns} = useGetBackInstQuery(plainte.id_plt)

    const soustitreStyle = {
        fontSize: '1.4em', // Taille de la police
        fontWeight: 'bolder', // Gras
        // color: '#1f5a92', // Couleur du texte (par exemple, bleu Bootstrap)
        // Ajoutez d'autres propriétés de style au besoin
      };

      const commentStyle = {
        fontSize: '1.2em', // Taille de la police
        fontWeight: 'normal', // Gras
        // color: '#000', // Couleur du texte (par exemple, bleu Bootstrap)
        paddingLeft: '35px'
        // Ajoutez d'autres propriétés de style au besoin
      };

    return loadObs || loadRslt || loadBackInv|| loadBackIns
    ? 
    ( <Spinner
        as="span"
        animation="grow"
        size="sm"
        role="status"
        aria-hidden="true"
      />) 
    :
(<>{!isEmpty(observation) && 
                <div className='mt-3 mb-4'>
                    <h4 style={soustitreStyle}>&nbsp; Observations</h4>
                    <div style={commentStyle} dangerouslySetInnerHTML={{ __html: observation?.comment_obs }} />
                    {(observation?.pieces_jointes && observation.pieces_jointes.length > 0) && 
                        <div style={commentStyle} className="col-lg-12 col-md-12">
                            <h6 style={{...soustitreStyle, fontSize: '1em'}}>Pièces Jointes</h6>
                            <Stack direction="row" spacing={5}>
                                {observation.pieces_jointes.map((piece, index) => (
                                    <a key={index} target="_blank" href={`https://mysqlapinode.leavesmartconsulting.com/file/${piece.nom_piece}`}>
                                        Pièces Jointes observation {++index}
                                    </a>
                                ))}
                            </Stack>
                        </div>
                    }
                </div>
            }

            {!isEmpty(resultatInvestigation) && 
                <div className='mt-3 mb-4'>
                    <h4 style={soustitreStyle}>&nbsp; Résultats d'investigation</h4>
                    <div  style={commentStyle} dangerouslySetInnerHTML={{ __html: resultatInvestigation?.comment_res }} />
                    {(resultatInvestigation?.pieces_rapports && resultatInvestigation.pieces_rapports.length > 0) && 
                        <div style={commentStyle} className="col-lg-12 col-md-12">
                            <h6 style={{...soustitreStyle, fontSize: '1em'}}>Pièces</h6>
                            <Stack direction="row" spacing={5}>
                                {resultatInvestigation.pieces_rapports.map((piece, index) => (
                                    <a key={index} target="_blank" href={`https://mysqlapinode.leavesmartconsulting.com/file/${piece.nom_piece}`}>
                                        Pièces Jointes résultat {++index}
                                    </a>
                                ))}
                            </Stack>
                        </div>
                    }
                </div>
            }
            {backInvestigation.length > 0 && 
                <div className='mt-3 mb-4'>
                    <h4 style={soustitreStyle}>&nbsp;Motif de retour en investigation</h4>
                    
                   { backInvestigation.map((item)=>{
                    return <div  style={commentStyle} dangerouslySetInnerHTML={{ __html: item?.lib_motif }} />
                   })
                    }
                    {(backInvestigation?.pieces_back_invests
                    && backInvestigation.pieces_back_invests.length > 0) && 
                        <div style={commentStyle} className="col-lg-12 col-md-12">
                            <h6 style={{...soustitreStyle, fontSize: '1em'}}>Pièces</h6>
                            <Stack direction="row" spacing={5}>
                                {backInvestigation.pieces_back_invests.map((piece, index) => (
                                    <a key={index} target="_blank" href={`https://mysqlapinode.leavesmartconsulting.com/file/${piece.nom_piece}`}>
                                        Pièces Jointes {++index}
                                    </a>
                                ))}
                            </Stack>
                        </div>
                    }
                </div>
            }
            {backInstruction.length > 0 && 
                <div className='mt-3 mb-4'>
                    <h4 style={soustitreStyle}>&nbsp; Motif de retour en instruction</h4>
                   { backInstruction.map((item)=>{
                    return <div  style={commentStyle} dangerouslySetInnerHTML={{ __html: item?.lib_motif }} />
                   })
                    }
                    {(backInstruction?.pieces_back_instructions && backInstruction.pieces_back_instructions.length > 0) && 
                        <div style={commentStyle} className="col-lg-12 col-md-12">
                            <h6 style={{...soustitreStyle, fontSize: '1em'}}>Pièces</h6>
                            <Stack direction="row" spacing={5}>
                                {backInstruction.pieces_back_instructions.map((piece, index) => (
                                    <a key={index} target="_blank" href={`https://mysqlapinode.leavesmartconsulting.com/file/${piece.nom_piece}`}>
                                        Pièces Jointes {++index}
                                    </a>
                                ))}
                            </Stack>
                        </div>
                    }
                </div>
            }

            
        </>)
}

export default Observation
