import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import Sidebar from "../../layouts/Sidebar";
import TablePlateforme from "./dataTable/TablePlateforme";
import { Loader } from "rsuite";
import { toast } from "react-toastify";
import {
  useGetPlateformeQuery,
  useCreatePlateformeMutation,
  useDeletePlateformeMutation,
} from "../../redux/apiSlice/plateformeApiSlice";
import { useCreateLogPageMutation } from "../../redux/apiSlice/logPageApiSlice";
import axios from "axios";
import Swal from "sweetalert2";
import { getUserInfo, isTokenExpired, logOut } from "../../redux/slice/userSlice";
import { useSelector, useDispatch } from "react-redux";
export default function Plateforme() {
  const {
    data: plateformes,
    refetch: refetchPlateforme,
    isLoading: loadPlateforme,
  } = useGetPlateformeQuery();
  const [createPlateforme, { isLoading: loadCreatePlateforme }] =
    useCreatePlateformeMutation();
  const [deletePlateforme] = useDeletePlateformeMutation();
  const [createLogPage, { isLoading: loadLog }] = useCreateLogPageMutation();
  // modal
  const [showDelete, setShowDelete] = useState(false);
  const handleCloseDelete = () => setShowDelete(false);
  const handleShowDelete = () => setShowDelete(true);

  ///// Skin Switch /////
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);

  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };
  const createLog = async () => {
    try {
      await createLogPage({
        content: "Page de gestion des plateformes",
      }).unwrap();
      // Swal.fire({
      //   title: "Log accomplie!",
      //   text: "Action réussie",
      //   icon: "success",
      // });
    } catch (error) {
      console.log("error", error);
    }
  };
  switchSkin(skin);
  const connectUser = useSelector(getUserInfo);
  const isExpired = useSelector(isTokenExpired);
  const dispash = useDispatch();
  
  useEffect(() => {
  if (isExpired) {
  dispash(logOut())
  window.location.href = "/";
  }  
  }, [isExpired]);
  
  useEffect(() => {
    createLog();
  }, []);

  useEffect(() => {
    if (!connectUser?.id) {
      window.location.href = "/";
    }
    switchSkin(skin);
  }, [skin]);

  const initPltaforme = {
    id_plateform: null,
    lib_plateform: "",
    url_plateform: "",
    image: "",
  };

  const [creerPlateforme, setCreerPlateforme] = useState({ ...initPltaforme });
  const [idPlateforme, setIdPlateforme] = useState(0);
  const [loader, setLoader] = useState(false);
  const isRequired =
    creerPlateforme.lib_plateform === "" ||
    creerPlateforme.url_plateform === "" ||
    creerPlateforme.image === "";

  const handleFileChange = async (e) => {
    const filesImg = e.target.files[0];
    recupererChamp("image", filesImg);
  };

  const [errorMsg1, setErrorMsg1] = useState({
    lib_plateform: "",
    url_plateform: "",
    image: "",
  });

  const informationMessage = {
    lib_plateform: "Veuillez saisi un libellé",
    url_plateform: "Veuillez saisi une url valide",
    image: "Veuillez choisir une image",
  };

  const afficherMessage = (objetMsg) => {
    Object.entries(objetMsg).forEach(([key, message]) => {
      if (creerPlateforme[key] === "") {
        toast.warning(message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    });
  };

  const recupererChamp = (field, value) => {
    setCreerPlateforme({ ...creerPlateforme, [field]: value });

    if (field === "lib_plateform" && value === "") {
      setErrorMsg1((prevErrors) => ({
        ...prevErrors,
        lib_plateform: "Veuillez saisi un libellé",
      }));
    } else if (field === "url_plateform" && value === "") {
      setErrorMsg1((prevErrors) => ({
        ...prevErrors,
        url_plateform: "Veuillez saisir une url valide",
      }));
    } else if (field === "image" && value === "") {
      setErrorMsg1((prevErrors) => ({
        ...prevErrors,
        image: "Veuillez choisir une image",
      }));
    } else {
      setErrorMsg1((prevErrors) => ({ ...prevErrors, [field]: "" }));
    }
  };

  const viderChamps = () => {
    setCreerPlateforme({ ...initPltaforme });
  };

  const savePlateforme = async () => {
    setLoader(true);
    const formData = new FormData();

    Object.keys(creerPlateforme).forEach((key) => {
      if (key !== "image") {
        formData.append(key, creerPlateforme[key]);
      }
    });

    try {
      if (creerPlateforme.id_plateform !== null) {
        if (creerPlateforme.image instanceof File) {
          formData.append(`image`, creerPlateforme.image);
        }
        await axios.post(
          `https://mysqlapinode.leavesmartconsulting.com/pigru/plateform/update/${creerPlateforme.id_plateform}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setLoader(false);
      } else {
        formData.append("image", creerPlateforme.image);
        await createPlateforme(formData).unwrap();
        setLoader(false);
      }

      Swal.fire({
        title: "SUCCES!",
        text: "Action réussie",
        icon: "success",
      });

      refetchPlateforme();
      setCreerPlateforme({ ...initPltaforme });
    } catch (error) {
      console.log("error", error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Une erreur s'est produite lors de la modification.",
      });
      setLoader(false);
    }
  };

  const suppressionPlateforme = async () => {
    try {
      let retour_supp = await deletePlateforme({
        id_plateform: idPlateforme,
      }).unwrap();
      if (retour_supp) {
        refetchPlateforme();
        handleCloseDelete();
        Swal.fire({
          title: "SUCCES!",
          text: "Action réussie",
          icon: "success",
        });
      }
    } catch (error) {
      // Affichez une notification d'erreur
      Swal.fire({
        icon: "ERREUR",
        title: "Oops...",
        text: "Erreur lors de la suppression",
        footer: '<a href="#">Essayer une autre fois?</a>',
      });
    }
  };

  return (
    <React.Fragment>
      <Sidebar />
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item active" aria-current="page">
                Gestion des plateformes
              </li>
            </ol>
            <h4 className="main-title mb-0">
              Liste des plateformes de l'Arcep
            </h4>
          </div>
        </div>

        <div className="container_liste_form">
          <div className="first_container">
            <span className="titre_cat">Liste des plateformes</span>

            <hr style={{ width: "100%" }} />

            {loadPlateforme ? (
              <p className="w-100 d-flex flex-row justify-content-center">
                <Loader size="md" content="Chargement en cours..." />
              </p>
            ) : (
              <TablePlateforme
                creerPlateforme={creerPlateforme}
                setCreerPlateforme={setCreerPlateforme}
                plateformes={plateformes?.data}
                handleShowDelete={handleShowDelete}
                setIdPlateforme={setIdPlateforme}
              />
            )}
          </div>

          <div className="second_container">
            <span className="titre_cat">Nouvelle plateforme</span>

            <hr style={{ width: "100%" }} />

            <div className="container_form">
              <div className="container_border">
                
                <div className="sous_container_form mb-2">
                  <div className="container_label">
                    <Form.Label htmlFor="exampleFormControlInput1">
                      Libellé :
                    </Form.Label>
                  </div>
                  <div style={{ width: "70%" }}>
                    <Form.Control
                      type="text"
                      id="exampleFormControlInput1"
                      placeholder="libellé de la plateforme"
                      value={creerPlateforme.lib_plateform}
                      onChange={(e) =>
                        recupererChamp("lib_plateform", e.target.value)
                      }
                    />
                    {errorMsg1.lib_plateform && (
                      <span className="text-danger">
                        {errorMsg1.lib_plateform}
                      </span>
                    )}
                  </div>
                </div>
                <div className="sous_container_form mb-2">
                  <div className="container_label">
                    <Form.Label htmlFor="exampleFormControlInput1">
                      Url :
                    </Form.Label>
                  </div>
                  <div style={{ width: "70%" }}>
                    <Form.Control
                      type="text"
                      id="exampleFormControlInput1"
                      placeholder="Url de la plateforme"
                      value={creerPlateforme.url_plateform}
                      onChange={(e) =>
                        recupererChamp("url_plateform", e.target.value)
                      }
                    />
                    {errorMsg1.url_plateform && (
                      <span className="text-danger">
                        {errorMsg1.url_plateform}
                      </span>
                    )}
                  </div>
                </div>
                <div className="sous_container_form mb-2">
                  <div className="container_label">
                    <Form.Label htmlFor="exampleFormControlInput1">
                      Logo :
                    </Form.Label>
                  </div>
                  <div style={{ width: "70%" }}>
                    <Form.Control
                      type="file"
                      id="exampleFormControlInput1"
                      placeholder="fichier"
                      onChange={(e) => handleFileChange(e)}
                    />
                    {errorMsg1.image && (
                      <span className="text-danger">
                        {errorMsg1.image}
                      </span>
                    )}
                  </div>
                </div>
                <div className="container_btn">
                  <Button variant="danger" onClick={() => viderChamps()}>
                    Annuler
                  </Button>

                  <Button
                    variant="success"
                    // onClick={() => savePlateforme()}
                    onClick={() =>
                      isRequired
                        ? afficherMessage(informationMessage)
                        : savePlateforme()
                    }
                    // disabled={isRequired || loadCreatePlateforme || loader}
                    disabled={loadCreatePlateforme || loader} 
                  >
                    {loadCreatePlateforme || loader ? (
                      <Loader size="md" content="En cours..." />
                    ) : (
                      <span>Enregister</span>
                    )}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* ----------------modal de modification---------- */}
        <Modal
          show={showDelete}
          onHide={handleCloseDelete}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Suppression</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4> Etês-vous sûr de vouloir supprimer ?</h4>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseDelete}>
              Annuler
            </Button>
            <Button variant="danger" onClick={suppressionPlateforme}>
              Valider
            </Button>
          </Modal.Footer>
        </Modal>

        <Footer />
      </div>
    </React.Fragment>
  );
}
