import React, { useEffect, useState,  } from "react";
import { Button, Card, Col, Row, Form, Modal, ProgressBar, Alert } from "react-bootstrap";
// import { Card, Col, Row } from "react-bootstrap";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import Sidebar from "../layouts/Sidebar";
import { NavLink } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { useSelector} from "react-redux";
import { getUserInfo } from "../redux/slice/userSlice";
import { useCreatePlainteExcelMutation } from "../redux/apiSlice/plainteApiSlice";
import Swal from "sweetalert2";
import * as XLSX from 'xlsx';

export default function SoumissionPlainte() {
  const navigate = useNavigate()

  
  const [barProgress, setBarProgress] = useState(50);
  const [file, setFile] = useState(null);

  // modal
  const [show, setShow] = useState(false);
  const [progress, setProgress] = useState(false);
  const handle = () => setShow(!show);
  const progresseHandleClose = () => setProgress(false);
  const progresseHandleShow = () => setProgress(true);

  const [createPlainteExcel, { isLoading: loadCreatePlainte }] =
  useCreatePlainteExcelMutation();



  // const [show1, setShow1] = useState(false);
  // const handleClose1 = () => setShow1(false);
  // const handleShow1 = () => setShow1(true);

  ///// Skin Switch /////
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);

  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  switchSkin(skin);
  const urlParams = new URLSearchParams(window.location.search);
  const registerParam = urlParams.get('register');

  const connectVerify = useSelector(getUserInfo);

  const handleFileUpload = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
  };

  
  const countEmptyRows = async(worksheet, rowCount) => {
   
    let emptyCells = [];

    const confirmResult = await Swal.fire({
      title: "Chargement de requetes",
      text: `Voulez-vous charger ${rowCount-1} requete(s) ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "OUI!",
      cancelButtonText: "NON!"
  });

  if (confirmResult.isConfirmed) {

   
    for (let row = 1; row <= rowCount; row++) {
      const cellAIsEmpty = !worksheet[`A${row}`];
      const cellBIsEmpty = !worksheet[`B${row}`];
      const cellFIsEmpty = !worksheet[`F${row}`];
      const cellGIsEmpty = !worksheet[`G${row}`];
      const cellHIsEmpty = !worksheet[`H${row}`];
      const cellIIsEmpty = !worksheet[`I${row}`];
      const cellMIsEmpty = !worksheet[`M${row}`];

      if (cellAIsEmpty) emptyCells.push(`A${row}`);
      if (cellBIsEmpty) emptyCells.push(`B${row}`);
      if (cellFIsEmpty) emptyCells.push(`F${row}`);
      if (cellGIsEmpty) emptyCells.push(`G${row}`);
      if (cellHIsEmpty) emptyCells.push(`H${row}`);
      if (cellIIsEmpty) emptyCells.push(`I${row}`);
      if (cellMIsEmpty) emptyCells.push(`M${row}`);
    }
    // console.log("worksheet", emptyCells)

    if (emptyCells.length > 0) {
      handle()
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `Veuillez renseigner les cellules : ${emptyCells.join(", ")}`,
      });
      
    } else {
      chargeExcel()
    }
    
    
  }else{
    handle()
    progresseHandleClose()
  }

 
  };


  const handleFileRead = () => {

    if (file == null) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Veuillez charger un fichier excel"
      });
    } else {

    const fileReader = new FileReader();
    fileReader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });

      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];

       // Compter le nombre de lignes non vides
    const rowCount = XLSX.utils.decode_range(worksheet['!ref']).e.r + 1;
    
      const { emptyCells } = countEmptyRows(worksheet, rowCount);


    };
    fileReader.readAsArrayBuffer(file);
  }
  };


  const chargeExcel = async ()=> {
    handle()
    progresseHandleShow()
      try {
        // console.log("fichier", fichier)
    
        const formData = new FormData();
        
        formData.append("file", file)
          const retour_chargement_fichier = await createPlainteExcel(formData).unwrap();
        
          // console.log("retour_chargement_fichier", retour_chargement_fichier)
    
      } catch (error) {
        
        console.log("error", error)
        
        if (error?.data =="Importation réussie.") {
          setBarProgress(100)
          // Swal.fire({
          //   title: "SUCCES!",
          //   text: "Importation réussie",
          //   icon: "success"
          // });
         
        } else {
          
          progresseHandleClose()
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Une erreur s'est produite, veuillez réessayer"
          });
        }
      }
      
    }

 
  useEffect(() => {

    if (!connectVerify?.id) {
      window.location.href = "/";
    }

    if (registerParam === 'true') {
      toast.success("Plainte ajoutée avec succès.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } 
    if (registerParam === 'false') {
      toast.success("Plaisanterie  ajoutée avec succès.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } 

    switchSkin(skin);
  }, [skin]);

  return (
    <React.Fragment>
      <Sidebar />
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <NavLink to="/dashboard">Dashboard</NavLink>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Soumission de plaintes
              </li>
            </ol>
            <h4 className="main-title mb-0">Dépot de plaintes</h4>
          </div>
        </div>

        <Row className="g-3">
          {[
            {
              bg: "primary",
              icon: "ri-survey-fill",
              value: "Nouvelle plainte",
              label: "Formulaire d'ajout d'une nouvelle plainte",
              link: "/plainte",
              modal: 0
            },
            {
              bg: "primary",
              icon: "ri-wallet-line",
              value: "Ajout des plaintes",
              label: "Charger plusieurs plaintes",
              modal: 1
            },
            {
              bg: "primary",
              icon: "ri-file-text-line",
              value: "Importer",
              label: "Template Excel de chargement",
              modal: 2
          
            },
          ].map((item, index) => (
            <Col sm="4" key={index}>
              {item.modal == 2 ?
               <a  target="_blank" href={`https://mysqlapinode.leavesmartconsulting.com/pigru/download_excel_file
               `}>
             
               <Card className="card-one">
                    <Card.Body className="p-3">
                      <div className="d-flex d-sm-block d-xl-flex align-items-center">
                        <div className={"helpdesk-icon text-white bg-" + item.bg}>
                          <i className={item.icon}></i>
                        </div>
                        <div className="ms-3 ms-sm-0 ms-xl-3 mt-sm-3 mt-xl-0">
                          <h2 className="card-value d-flex align-items-baseline mb-0">
                            {item.value}{" "}
                          </h2>
                          <label className="card-label fs-sm fw-medium mb-1">
                            {item.label}
                          </label>
                          <div className="mutual-badge"></div>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </a> 
              
            :
            <NavLink to={item.link} onClick={()=> item.modal == 1 && handle()}>
            <Card className="card-one">
              <Card.Body className="p-3">
                <div className="d-flex d-sm-block d-xl-flex align-items-center">
                  <div className={"helpdesk-icon text-white bg-" + item.bg}>
                    <i className={item.icon}></i>
                  </div>
                  <div className="ms-3 ms-sm-0 ms-xl-3 mt-sm-3 mt-xl-0">
                    <h2 className="card-value d-flex align-items-baseline mb-0">
                      {item.value}{" "}
                    </h2>
                    <label className="card-label fs-sm fw-medium mb-1">
                      {item.label}
                    </label>
                    <div className="mutual-badge"></div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </NavLink>
            }
            </Col>
          ))}
        </Row>
        <Row className="mt-5">
   
        </Row>
        <Footer />
 <Modal
        show={show}
        onHide={handle}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Chargement de plainte</Modal.Title>

        </Modal.Header>
        <Modal.Body>
          {/* <Row>
       
            <Col >
            <a className="btn btn-secondary" target="_blank" href={`https://mysqlapinode.leavesmartconsulting.com/file/template_excel_plainte.xlsx`}>
             
            Télécharger format
                                   </a>
          </Col>
          <Col ></Col>
          </Row> */}
          <Row>
            <Col >
           <Form.Label htmlFor="upload">
           Choisir votre fichier excel
          </Form.Label>
          <Form.Control
           onChange={handleFileUpload}
            type="file"
            id="upload"
            placeholder=""
            name="file"
            accept=".xls, .xlsx"
            style={{
              border: "1px solid #1f365c",
              boxShadow: "0 0 5px rgba(0, 0, 255, 0.5)",
              borderRadius: "5px",
              padding: "8px"
            }}
          />
          </Col>
          </Row>
          
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handle}>
            Annuler
          </Button>
          <Button variant="success" onClick={handleFileRead}>
            Enregistrer
          </Button>
        </Modal.Footer>
      </Modal>
 <Modal
        show={progress}
        onHide={progresseHandleClose}
        backdrop="static"    
        keyboard={false}
      >
        <Modal.Header >
          <Modal.Title>CHARGEMENT DE REQUETE </Modal.Title>

        </Modal.Header>
        <Modal.Body>
      
          <Row>
            <Col >
             <ProgressBar animated now={barProgress} />
          </Col>
          </Row>
          {barProgress == 100 && 
          <Row>
            <Col >
            <Alert variant="success">
            Importation réussie !!!
</Alert>
          </Col>
          </Row>}
          
        </Modal.Body>

       {barProgress == 100 && <Modal.Footer>
        <Button variant="primary" onClick={() => window.location.href = "/depot-plainte"}>
  Fermer
</Button>

        </Modal.Footer>}
      </Modal>
      
      </div>
    </React.Fragment>
  );
}
